.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.stage {
  margin: 0 auto;
  width: 300px;
  display: flex;
  align-items: center;
  height: calc(100vh - 20px);
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  -webkit-animation: dot-pulse-before 1.5s infinite linear;
  animation: dot-pulse-before 1.5s infinite linear;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  -webkit-animation: dot-pulse-after 1.5s infinite linear;
  animation: dot-pulse-after 1.5s infinite linear;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@-webkit-keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@-webkit-keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

.button-action-not-allowed {
  cursor: not-allowed !important;
  pointer-events: none;
}
.noBorder {
  border: 0 !important;
}
.member-info-additional-details {
  display: flex;
  height: 50px;
  padding: 0 35px !important;
}
.member-info-name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  height: 24px;
  margin-left: 10px;
  margin-top: 10px;
  line-height: 28px;
}

.member-info-age {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  margin-left: 10px !important;
  padding: 0 2px;
}

.member-info-vip {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  height: 22px;
  background: #b2ddff;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.member-info-nps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  width: 75px;
  height: 22px;
  background: #ddd6fe;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.corporate-tag {
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  width: 85px;
  height: 22px;
  color: white;
  background: #579c30;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.member-info-member-tree {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;

  height: 22px;
  background: #f8fafc;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.member-info-text {
  padding-left: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.member-info-title {
  color: #667085;
}

.member-info-value {
  color: #101828;
}
.member-info-email {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.member-info-link {
  color: #2e90fa;
}

.member-communication-message {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #101828;
  flex: none;
  order: 0;
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
}

.self-message {
  background: #4b5565;
  color: #ffffff;
}

.participant-message {
  background: #f2f4f7;
}

.member-communication-message-section {
  width: 85%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 15px;
}

.member-communication-message-header {
  display: inline-block;
  width: 100%;
}

.align-left {
  float: left;
}

.align-right {
  margin-left: auto;
}

.block-display {
  display: block;
}

.flex-display {
  display: flex;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.message-time {
  color: #667085;
  font-size: 12px;
}

.message-edit-section {
  box-sizing: border-box;
  display: block;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 14px;
  gap: 8px;
  width: 100%;
  height: 340px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  flex: none;
  overflow-y: auto;
}

.padding-5-0 {
  padding: 5px 0px;
}

.signature-details {
  color: #4b5565;
  display: block;
}

.signature-name {
  font-size: '1em';
  font-weight: 'bold';
  padding: '5px 0px';
}

.text-show-more {
  cursor: pointer;
  font-size: 12px;
  color: #2e90fa;
}
